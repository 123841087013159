
/* eslint-disable global-require */

// The top-level (parent) route
const routes = {
  path: '',

  // Keep in mind, routes are evaluated in order
  children: [
    {
      path: '/',
      load: () => new Promise((resolve, reject) => {
        resolve(require('./home'));
        reject(error);
      }),
    },
    {
      path: '/contact',
      load: () => new Promise((resolve, reject) => {
        resolve(require('./contact'));
        reject(error);
      }),
    },
    {
      path: '/about',
      load: () => new Promise((resolve, reject) => {
        resolve(require('./about'));
        reject(error);
      }),
    },
    {
      path: '/privacy',
      load: () => new Promise((resolve, reject) => {
        resolve(require('./privacy'));
        reject(error);
      }),
    },
    {
      path: '/login',
      load: () => new Promise((resolve, reject) => {
        resolve(require('./login'));
        reject(error);
      }),
    },
    {
      path: '/siteadmin',
      load: () => new Promise((resolve, reject) => {
        resolve(require('./site-admin/dashboard'));
        reject(error);
      }),
    },
    {
      path: '/inputform',
      load: () => new Promise((resolve, reject) => {
        resolve(require('./site-admin/inputform'));
        reject(error);
      }),
    },
    {
      path: '/table',
      load: () => new Promise((resolve, reject) => {
        resolve(require('./site-admin/table'));
        reject(error);
      }),
    },

    {
      path: '/siteadmin/riders',
      load: () => new Promise((resolve, reject) => {
        resolve(require('./site-admin/riders'));
        reject(error);
      }),
    },
    {
      path: '/siteadmin/drivers',
      load: () => new Promise((resolve, reject) => {
        resolve(require('./site-admin/drivers'));
        reject(error);
      }),
    },
    {
      path: '/siteadmin/category',
      load: () => new Promise((resolve, reject) => {
        resolve(require('./site-admin/category'));
        reject(error);
      }),
    },
    {
      path: '/siteadmin/vehicles',
      load: () => new Promise((resolve, reject) => {
        resolve(require('./site-admin/vehicles'));
        reject(error);
      }),
    },
    {
      path: '/siteadmin/bookings',
      load: () => new Promise((resolve, reject) => {
        resolve(require('./site-admin/booking'));
        reject(error);
      }),
    },
    {
      path: '/siteadmin/category/add',
      load: () => new Promise((resolve, reject) => {
        resolve(require('./site-admin/addCategory'));
        reject(error);
      }),
    },
    {
      path: '/siteadmin/:from/view/:id',
      load: () => new Promise((resolve, reject) => {
        resolve(require('./site-admin/viewBooking'));
        reject(error);
      }),
    },
    {
      path: '/siteadmin/riders/:id',
      load: () => new Promise((resolve, reject) => {
        resolve(require('./site-admin/editRider'));
        reject(error);
      }),
    },
    {
      path: '/siteadmin/drivers/:id',
      load: () => new Promise((resolve, reject) => {
        resolve(require('./site-admin/editDriver'));
        reject(error);
      }),
    },
    {
      path: '/siteadmin/category/edit/:id',
      load: () => new Promise((resolve, reject) => {
        resolve(require('./site-admin/editCategory'));
        reject(error);
      }),
    },
    {
      path: '/siteadmin/vehicles/:id',
      load: () => new Promise((resolve, reject) => {
        resolve(require('./site-admin/editVehicle'));
        reject(error);
      }),
    },
    {
      path: '/siteadmin/promo-code/list',
      load: () => new Promise((resolve, reject) => {
        resolve(require('./site-admin/promoCode/promoCodeList'));
        reject(error);
      }),
    },
    {
      path: '/siteadmin/promo-code/add',
      load: () => new Promise((resolve, reject) => {
        resolve(require('./site-admin/promoCode/addPromoCode'));
        reject(error);
      }),
    },
    {
      path: '/siteadmin/promo-code/edit/:id',
      load: () => new Promise((resolve, reject) => {
        resolve(require('./site-admin/promoCode/editPromoCode'));
        reject(error);
      }),
    },
    {
      path: '/siteadmin/completed-bookings',
      load: () => new Promise((resolve, reject) => {
        resolve(require('./site-admin/completedBooking'));
        reject(error);
      }),
    },
    {
      path: '/siteadmin/cancelled-bookings',
      load: () => new Promise((resolve, reject) => {
        resolve(require('./site-admin/cancelledBooking'));
        reject(error);
      }),
    },
    {
      path: '/siteadmin/currency',
      load: () => new Promise((resolve, reject) => {
        resolve(require('./site-admin/currency'));
        reject(error);
      }),
    },
    {
      path: '/siteadmin/settings/site',
      load: () => new Promise((resolve, reject) => {
        resolve(require('./site-admin/siteSettings'));
        reject(error);
      }),
    },
    {
      path: '/siteadmin/change/admin',
      load: () => new Promise((resolve, reject) => {
        resolve(require('./site-admin/changeAdmin'));
        reject(error);
      }),
    },
    {
      path: '/siteadmin/notifications',
      load: () => new Promise((resolve, reject) => {
        resolve(require('./site-admin/manageNotifications'));
        reject(error);
      }),
    },
    {
      path: '/siteadmin/cancel-reasons',
      load: () => new Promise((resolve, reject) => {
        resolve(require('./site-admin/manageCancelReasons'));
        reject(error);
      }),
    },
    {
      path: '/siteadmin/ratings',
      load: () => new Promise((resolve, reject) => {
        resolve(require('./site-admin/ratings'));
        reject(error);
      }),
    },
    {
      path: '/siteadmin/cancel-reasons/add',
      load: () => new Promise((resolve, reject) => {
        resolve(require('./site-admin/addCancelReason'));
        reject(error);
      }),
    },
    {
      path: '/siteadmin/cancel-reasons/edit/:id',
      load: () => new Promise((resolve, reject) => {
        resolve(require('./site-admin/editCancelReason'));
        reject(error);
      }),
    },
    {
      path: '/siteadmin/manage-location',
      load: () => new Promise((resolve, reject) => {
        resolve(require('./site-admin/manageLocation'));
        reject(error);
      }),
    },
    {
      path: '/siteadmin/location',
      load: () => new Promise((resolve, reject) => {
        resolve(require('./site-admin/manageLocationList'));
        reject(error);
      }),
    },
    {
      path: '/siteadmin/edit-location/:id',
      load: () => new Promise((resolve, reject) => {
        resolve(require('./site-admin/editLocation'));
        reject(error);
      }),
    },
    {
      path: '/siteadmin/homepage/banner',
      load: () => new Promise((resolve, reject) => {
        resolve(require('./site-admin/homeSettings'));
        reject(error);
      }),
    },
    {
      path: '/siteadmin/homepage/topfeature',
      load: () => new Promise((resolve, reject) => {
        resolve(require('./site-admin/aboutSettings'));
        reject(error);
      }),
    },
    {
      path: '/siteadmin/homepage/category',
      load: () => new Promise((resolve, reject) => {
        resolve(require('./site-admin/citySettings'));
        reject(error);
      }),
    },
    {
      path: '/siteadmin/homepage/rider',
      load: () => new Promise((resolve, reject) => {
        resolve(require('./site-admin/safetySettings'));
        reject(error);
      }),
    },
    {
      path: '/siteadmin/homepage/driver',
      load: () => new Promise((resolve, reject) => {
        resolve(require('./site-admin/signupSettings'));
        reject(error);
      }),
    },
    {
      path: '/siteadmin/homepage/footer',
      load: () => new Promise((resolve, reject) => {
        resolve(require('./site-admin/footerSettings'));
        reject(error);
      }),
    },
    {
      path: '/siteadmin/payout',
      load: () => new Promise((resolve, reject) => {
        resolve(require('./site-admin/autoPayout'));
        reject(error);
      }),
    },
    {
      path: '/siteadmin/failed-payout',
      load: () => new Promise((resolve, reject) => {
        resolve(require('./site-admin/failedPayout'));
        reject(error);
      }),
    },
    {
      path: '/siteadmin/staticpage/manage',
      load: () => new Promise((resolve, reject) => {
        resolve(require('./site-admin/staticPage'));
        reject(error);
      }),
    },
    {
      path: '/siteadmin/contentpage/manage',
      load: () => new Promise((resolve, reject) => {
        resolve(require('./site-admin/contentPage'));
        reject(error);
      }),
    },
    {
      path: '/siteadmin/contentpage/add',
      load: () => new Promise((resolve, reject) => {
        resolve(require('./site-admin/addContentPage'));
        reject(error);
      }),
    },
    {
      path: '/siteadmin/contentpage/edit/:pageId',
      load: () => new Promise((resolve, reject) => {
        resolve(require('./site-admin/editContentPage'));
        reject(error);
      }),
    },
    {
      path: '/support',
      load: () => new Promise((resolve, reject) => {
        resolve(require('./static/support'));
        reject(error);
      }),
    },
    {
      path: '/driver/privacy-policy',
      load: () => new Promise((resolve, reject) => {
        resolve(require('./static/driverPrivacyPolicy'));
        reject(error);
      }),
    },
    {
      path: '/siteadmin/staticpage/edit/:pageId',
      load: () => new Promise((resolve, reject) => {
        resolve(require('./site-admin/editStaticPage'));
        reject(error);
      }),
    },
    {
      path: '/rider',
      load: () => new Promise((resolve, reject) => {
        resolve(require('./static/rider'));
        reject(error);
      }),
    },
    {
      path: '/driver',
      load: () => new Promise((resolve, reject) => {
        resolve(require('./static/driver'));
        reject(error);
      }),
    },
    {
      path: '/legal',
      load: () => new Promise((resolve, reject) => {
        resolve(require('./static/legal'));
        reject(error);
      }),
    },
    {
      path: '/siteadmin/pricing/list',
      load: () => new Promise((resolve, reject) => {
        resolve(require('./site-admin/pricing/pricingList'));
        reject(error);
      }),
    },
    {
      path: '/siteadmin/pricing/add',
      load: () => new Promise((resolve, reject) => {
        resolve(require('./site-admin/pricing/addPricing'));
        reject(error);
      }),
    },
    {
      path: '/siteadmin/pricing/edit/:id',
      load: () => new Promise((resolve, reject) => {
        resolve(require('./site-admin/pricing/editPricing'));
        reject(error);
      }),
    },
    {
      path: '/siteadmin/admin-roles',
      load: () => new Promise((resolve, reject) => {
        resolve(require('./site-admin/adminRoles'));
        reject(error);
      }),
    },
    {
      path: '/siteadmin/admin-users',
      load: () => new Promise((resolve, reject) => {
        resolve(require('./site-admin/adminUser'));
        reject(error);
      }),
    },
    {
      path: '/siteadmin/precaution-notification',
      load: () => new Promise((resolve, reject) => {
        resolve(require('./site-admin/precautionNotification/updatePrecautionNotification'));
        reject(error);
      }),
    },
    {
      path: '/siteadmin/schedule-bookings',
      load: () => new Promise((resolve, reject) => {
        resolve(require('./site-admin/scheduleBooking'));
        reject(error);
      }),
    },
    {
      path: '/siteadmin/:from/chat-message/:id',
      load: () => new Promise((resolve, reject) => {
        resolve(require('./site-admin/chatMessage'));
        reject(error);
      }),
    },
    {
      path: '/siteadmin/manual-bookings',
      load: () => new Promise((resolve, reject) => {
        resolve(require('./site-admin/manualBooking'));
        reject(error);
      }),
    },
    {
      path: '/siteadmin/manual-bookings/create',
      load: () => new Promise((resolve, reject) => {
        resolve(require('./site-admin/createBooking'));
        reject(error);
      }),
    },
    {
      path: '/page/:pageUrl',
      load: () => new Promise((resolve, reject) => {
        resolve(require('./page'));
        reject(error);
      }),
    },
    {
      path: '/siteadmin/tracking',
      load: () => new Promise((resolve, reject) => {
        resolve(require('./site-admin/tracking'));
        reject(error);
      }),
    },
    {
      path: '/siteadmin/settings/mobile-app',
      load: () => new Promise((resolve, reject) => {
        resolve(require('./site-admin/mobileSettings'));
        reject(error);
      }),
    },
    {
      path: '/siteadmin/settings/configuration',
      load: () => new Promise((resolve, reject) => {
        resolve(require('./site-admin/configSettings'));
        reject(error);
      }),
    },
    {
      path: '/siteadmin/payment-gateway',
      load: () => new Promise((resolve, reject) => {
        resolve(require('./site-admin/paymentGateway'));
        reject(error);
      }),
    },
    {
      path: '/siteadmin/email-template/manage',
      load: () => new Promise((resolve, reject) => {
        resolve(require('./site-admin/emailTemplate'));
        reject(error);
      }),
    },
    {
      path: '/siteadmin/email-template/add',
      load: () => new Promise((resolve, reject) => {
        resolve(require('./site-admin/addEmailTemplatePage'));
        reject(error);
      }),
    },
    {
      path: '/siteadmin/email-template/edit/:pageId',
      load: () => new Promise((resolve, reject) => {
        resolve(require('./site-admin/editEmailTemplatePage'));
        reject(error);
      }),
    },
    // {
    //   path: '/siteadmin/adminRoles',
    //   load: () => require(/* webpackChunkName: 'dashboard39' */ './page')
    // },

    // Wildcard routes, e.g. { path: '(.*)', ... } (must go last)
    {
      path: '(.*)',
      load: () => new Promise((resolve, reject) => {
        resolve(require('./not-found'));
        reject(error);
      }),
    },
  ],

  async action({ next }) {
    // Execute each child route until one of them return the result
    const route = await next();

    // Provide default values for title, description etc.
    route.title = `${route.title || 'Untitled Page'}`;
    route.description = route.description || '';

    return route;
  },
};

// The error page is available by permanent url for development mode
if (__DEV__) {
  routes.children.unshift({
    path: '/error',
    action: require('./error').default,
  });
}

export default routes;
