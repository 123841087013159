import gql from 'graphql-tag';
import histroy from '../../history';
import { toastr } from 'react-redux-toastr';
import { setLoaderStart, setLoaderComplete } from '../../actions/loader/loader';

export function addEmailTemplate(values) {

  return async (dispatch, getState, { client }) => {
    const mutation = gql`
    query addEmailTemplate(
          $emailPurpose: String,
          $emailTitle: String,
          $content: String
        ) {
        addEmailTemplate(
            emailPurpose: $emailPurpose,
            emailTitle: $emailTitle,
            content: $content
        ){
          status
          errorMessage
        }
      }
    `;

    dispatch(setLoaderStart('AddEmailTemplate'));

    const { data } = await client.mutate({
      mutation,
      variables: {
        ...values
      }
    });
    
    dispatch(setLoaderComplete('AddEmailTemplate'));

    if (data && data.addEmailTemplate && data.addEmailTemplate.status === 200) {
      toastr.success('Success!', 'The email template has been added successfully.');
      histroy.push('/siteadmin/email-template/manage');
    } else if (data && data.addEmailTemplate && data.addEmailTemplate.status !== 200){
      toastr.error('Error!', data.addEmailTemplate.errorMessage);
    } else {
      toastr.error('Error!', "Something went wrong. Please try again.");
    }
  }
}