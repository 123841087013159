import gql from 'graphql-tag';
import histroy from '../../history';
import { toastr } from 'react-redux-toastr';
import { setLoaderStart, setLoaderComplete } from '../../actions/loader/loader';

export function editEmailTemplate(values) {

  return async (dispatch, getState, { client }) => {
    const mutation = gql`
      mutation updateEmailTemplate(
        $id: Int, 
        $emailPurpose: String,
        $emailTitle: String,
        $content: String
      ) {
        updateEmailTemplate(
          id: $id,
          emailPurpose: $emailPurpose,
          emailTitle: $emailTitle,
          content: $content
        ){
          status
          errorMessage
        }
      }
    `;

    dispatch(setLoaderStart('EditEmailTemplate'));

    const { data } = await client.mutate({
      mutation,
      variables: {
        ...values
      }
    });

    dispatch(setLoaderComplete('EditEmailTemplate'));
    if (data && data.updateEmailTemplate && data.updateEmailTemplate.status === 200) {
      histroy.push('/siteadmin/email-template/manage');
      toastr.success('Success', 'Email Template has been updated!')
    } else if (data && data.updateEmailTemplate && data.updateEmailTemplate.status === 400) {
      toastr.error('Error!', data.updateEmailTemplate.errorMessage)
    }
  }
}