import { addLocation } from '../../../actions/siteadmin/addLocation';
import { toastr } from 'react-redux-toastr';

async function submit(values, dispatch) {
   console.log(values, "rew")
   await dispatch(
        addLocation(
            values.locationName,
            JSON.stringify(values.path),
            values.description,
            values.isReserveOnly == "false" ? false : true
        )
    )
}

export default submit;