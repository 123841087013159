import {
    DELETE_EMAIL_TEMPLATE_START,
    DELETE_EMAIL_TEMPLATE_ERROR,
    DELETE_EMAIL_TEMPLATE_SUCCESS
} from '../../constants';
import gql from 'graphql-tag';
import { toastr } from 'react-redux-toastr';

export function deleteEmailTemplate(id, currentPage) {
    return async (dispatch, getState, { client }) => {
        let errorMessage;
        dispatch({
            type: DELETE_EMAIL_TEMPLATE_START
        });

        try {
            let query = gql `
            query getEmailTemplateList($currentPage: Int){
                getEmailTemplateList(currentPage: $currentPage){
                count
                EmailTemplateData{
                    id
                    emailPurpose
                    emailTitle
                    content
                }
                }
            }
            `
            let mutation= gql `
            mutation deleteEmailTemplate($id: Int) {
                deleteEmailTemplate(id: $id) {
                  status
                  errorMessage
                }
              }
            `
            const { data } = await client.mutate({
                mutation,
                variables: {
                    id
                },
                refetchQueries: [{ query, variables: { currentPage } }]
            });



            if(data && data.deleteEmailTemplate && data.deleteEmailTemplate.status == 200) {
                dispatch({
                    type: DELETE_EMAIL_TEMPLATE_SUCCESS
                })
                toastr.success('Success!', 'The email template has been deleted successfully.')
            } else {
                errorMessage = data && data.deleteEmailTemplate && data.deleteEmailTemplate.errorMessage;
                errorMessage = errorMessage ? errorMessage : 'Something went wrong.';

                dispatch({
                    type: DELETE_EMAIL_TEMPLATE_ERROR
                })

                toastr.error('Error!', errorMessage)
            }

        } catch (err) {
            dispatch({
                type: DELETE_EMAIL_TEMPLATE_ERROR
            })
            toastr.error('Error!', 'Something went wrong.' + err)
        }
    }
}