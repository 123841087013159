import { toastr } from 'react-redux-toastr';
import { editEmailTemplate } from '../../../actions/siteadmin/editEmailTemplate';
async function submit(values, dispatch) {

    if (values.content == null || values.content == '<p><br></p>' || values.content == '<p> </p>') {
        toastr.error("Error Occured", "Please Add  Content");
    } else {
        console.log(values)
        await dispatch(editEmailTemplate(values));
    }
}

export default submit;