import messages from '../../../locale/messages'

const validate = values => {
    const errors = {}
    let content = values && values.content && values.content.replace('<p>', '').replace('</p>', '')
    if (!values.content) {
      errors.content = messages.required;
    } else if (content.trim() == "") {
      errors.content = messages.required
    }
  
    if (!values.emailPurpose) {
      errors.emailPurpose = messages.required;
    } else if (values.emailPurpose.trim() == "") {
      errors.emailPurpose = messages.required
    }
  
    if (!values.emailTitle) {
      errors.emailTitle = messages.required;
    } else if (values.emailTitle.trim() == "") {
      errors.emailTitle = messages.required
    }
  
    return errors
  }
  
  export default validate
  