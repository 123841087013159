/* eslint-disable import/prefer-default-export */

export const SET_RUNTIME_VARIABLE = 'SET_RUNTIME_VARIABLE';

export const SET_LOCALE_START = 'SET_LOCALE_START';
export const SET_LOCALE_SUCCESS = 'SET_LOCALE_SUCCESS';
export const SET_LOCALE_ERROR = 'SET_LOCALE_ERROR';

// Get Currency List from Currencies Table
export const GET_CURRENCIES_START = 'GET_CURRENCIES_START';
export const GET_CURRENCIES_SUCCESS = 'GET_CURRENCIES_SUCCESS';
export const GET_CURRENCIES_ERROR = 'GET_CURRENCIES_ERROR';

// Currency Rates Fetch
export const CURRENCY_RATES_FETCH_START = 'CURRENCY_RATES_FETCH_START';
export const CURRENCY_RATES_FETCH_SUCCESS = 'CURRENCY_RATES_FETCH_SUCCESS';
export const CURRENCY_RATES_FETCH_ERROR = 'CURRENCY_RATES_FETCH_ERROR';

// Select Currency from Dropdown
export const CHOSE_TO_CURRENCY_START = 'CHOSE_TO_CURRENCY_START';
export const CHOSE_TO_CURRENCY_SUCCESS = 'CHOSE_TO_CURRENCY_SUCCESS';
export const CHOSE_TO_CURRENCY_ERROR = 'CHOSE_TO_CURRENCY_ERROR';

// Logout
export const ADMIN_USER_LOGOUT_START = "ADMIN_USER_LOGOUT_START";
export const ADMIN_USER_LOGOUT_SUCCESS = "ADMIN_USER_LOGOUT_SUCCESS";
export const ADMIN_USER_LOGOUT_ERROR = "ADMIN_USER_LOGOUT_ERROR";

//Category Image Delete
export const CATEGORY_IMAGE_UPLOAD_START = "CATEGORY_IMAGE_UPLOAD_START";
export const CATEGORY_IMAGE_UPLOAD_SUCCESS = "CATEGORY_IMAGE_UPLOAD_SUCCESS";
export const CATEGORY_IMAGE_UPLOAD_ERROR = "CATEGORY_IMAGE_UPLOAD_ERROR";

//Category Marker Delete
export const CATEGORY_MARKER_UPLOAD_START = "CATEGORY_MARKER_UPLOAD_START";
export const CATEGORY_MARKER_UPLOAD_SUCCESS = "CATEGORY_MARKER_UPLOAD_SUCCESS";
export const CATEGORY_MARKER_UPLOAD_ERROR = "CATEGORY_MARKER_UPLOAD_ERROR";

//User Delete
export const USER_DELETE_START = 'USER_DELETE_START';
export const USER_DELETE_SUCCESS = 'USER_DELETE_SUCCESS';
export const USER_DELETE_ERROR = 'USER_DELETE_ERROR';

//Cancel Reason Delete
export const CANCEL_REASON_DELETE_START = 'CANCEL_REASON_DELETE_START';
export const CANCEL_REASON_DELETE_SUCCESS = 'CANCEL_REASON_DELETE_SUCCESS';
export const CANCEL_REASON_DELETE_ERROR = 'CANCEL_REASON_DELETE_ERROR';

//Driver/Rider profile image upload
export const PROFILE_IMAGE_UPLOAD_START = 'PROFILE_IMAGE_UPLOAD_START';
export const PROFILE_IMAGE_UPLOAD_SUCCESS = 'PROFILE_IMAGE_UPLOAD_SUCCESS';
export const PROFILE_IMAGE_UPLOAD_ERROR = 'PROFILE_IMAGE_UPLOAD_ERROR';

//Driver Licence upload
export const LICENCE_IMAGE_UPLOAD_START = 'LICENCE_IMAGE_UPLOAD_START';
export const LICENCE_IMAGE_UPLOAD_SUCCESS = 'LICENCE_IMAGE_UPLOAD_SUCCESS';
export const LICENCE_IMAGE_UPLOAD_ERROR = 'LICENCE_IMAGE_UPLOAD_ERROR';

//Driver RCbook upload
export const RCBOOK_IMAGE_UPLOAD_START = 'RCBOOK_IMAGE_UPLOAD_START';
export const RCBOOK_IMAGE_UPLOAD_SUCCESS = 'RCBOOK_IMAGE_UPLOAD_SUCCESS';
export const RCBOOK_IMAGE_UPLOAD_ERROR = 'RCBOOK_IMAGE_UPLOAD_ERROR';

//Driver Insurance upload
export const INSURANCE_IMAGE_UPLOAD_START = 'INSURANCE_IMAGE_UPLOAD_START';
export const INSURANCE_IMAGE_UPLOAD_SUCCESS = 'INSURANCE_IMAGE_UPLOAD_SUCCESS';
export const INSURANCE_IMAGE_UPLOAD_ERROR = 'INSURANCE_IMAGE_UPLOAD_ERROR';

// ADD/UPDATE Promo Code 
export const ADD_PROMOCODE_START = 'ADD_PROMOCODE_START';
export const ADD_PROMOCODE_SUCCESS = 'ADD_PROMOCODE_SUCCESS';
export const ADD_PROMOCODE_ERROR = 'ADD_PROMOCODE_ERROR';

// Delete Promo Code 
export const DELETE_PROMOCODE_START = 'DELETE_PROMOCODE_START';
export const DELETE_PROMOCODE_SUCCESS = 'DELETE_PROMOCODE_SUCCESS';
export const DELETE_PROMOCODE_ERROR = 'DELETE_PROMOCODE_ERROR';

// Enable / Disable Currencies
export const CHANGE_CURRENCY_STATUS_START = 'CHANGE_CURRENCY_STATUS_START';
export const CHANGE_CURRENCY_STATUS_SUCCESS = 'CHANGE_CURRENCY_STATUS_SUCCESS';
export const CHANGE_CURRENCY_STATUS_ERROR = 'CHANGE_CURRENCY_STATUS_ERROR';

// Set Base Currency
export const SET_BASE_CURRENCY_START = 'SET_BASE_CURRENCY_START';
export const SET_BASE_CURRENCY_SUCCESS = 'SET_BASE_CURRENCY_SUCCESS';
export const SET_BASE_CURRENCY_ERROR = 'SET_BASE_CURRENCY_ERROR';
//Site Settings upload
export const SITE_SETTINGS_UPLOAD_START = 'SITE_SETTINGS_UPLOAD_START';
export const SITE_SETTINGS_UPLOAD_SUCCESS = 'SITE_SETTINGS_UPLOAD_SUCCESS';
export const SITE_SETTINGS_UPLOAD_ERROR = 'SITE_SETTINGS_UPLOAD_ERROR';

//Homepage Settings upload
export const HOMEPAGE_SETTINGS_UPLOAD_START = 'HOMEPAGE_SETTINGS_UPLOAD_START';
export const HOMEPAGE_SETTINGS_UPLOAD_SUCCESS = 'HOMEPAGE_SETTINGS_UPLOAD_SUCCESS';
export const HOMEPAGE_SETTINGS_UPLOAD_ERROR = 'HOMEPAGE_SETTINGS_UPLOAD_ERROR';

//Site Settings
export const SITE_SETTINGS_START = 'SITE_SETTINGS_START';
export const SITE_SETTINGS_SUCCESS = 'SITE_SETTINGS_SUCCESS';
export const SITE_SETTINGS_ERROR = 'SITE_SETTINGS_ERROR';

// Loader Buttons
export const SET_LOADER_START = 'SET_LOADER_START';
export const SET_LOADER_COMPLETE = 'SET_LOADER_COMPLETE';

// ADD/UPDATE Promo Code 
export const ADD_CANCEL_REASON_START = 'ADD_CANCEL_REASON_START';
export const ADD_CANCEL_REASON_SUCCESS = 'ADD_CANCEL_REASON_SUCCESS';
export const ADD_CANCEL_REASON_ERROR = 'ADD_CANCEL_REASON_ERROR';

// Delete location
export const DELETE_LOCATION_START = 'DELETE_LOCATION_START';
export const DELETE_LOCATION_SUCCESS = 'DELETE_LOCATION_SUCCESS';
export const DELETE_LOCATION_ERROR = 'DELETE_LOCATION_ERROR';

//UPDATE Payout status 
export const SET_PAYOUT_START = 'SET_PAYOUT_START';
export const SET_PAYOUT_SUCCESS = 'SET_PAYOUT_SUCCESS';
export const SET_PAYOUT_ERROR = 'SET_PAYOUT_ERROR';

//UPDATE Cash Payout status 
export const SET_CASH_PAYOUT_START = 'SET_CASH_PAYOUT_START';
export const SET_CASH_PAYOUT_SUCCESS = 'SET_CASH_PAYOUT_SUCCESS';
export const SET_CASH_PAYOUT_ERROR = 'SET_CASH_PAYOUT_ERROR';

// Update Static Page Details
export const ADMIN_UPDATE_STATIC_START = 'ADMIN_UPDATE_STATIC_START';
export const ADMIN_UPDATE_STATIC_SUCCESS = 'ADMIN_UPDATE_STATIC_SUCCESS';
export const ADMIN_UPDATE_STATIC_ERROR = 'ADMIN_UPDATE_STATIC_ERROR';

//Category Delete
export const CATEGORY_DELETE_START = 'CATEGORY_DELETE_START';
export const CATEGORY_DELETE_SUCCESS = 'CATEGORY_DELETE_SUCCESS';
export const CATEGORY_DELETE_ERROR = 'CATEGORY_DELETE_ERROR';

//Category Update
export const CATEGORY_UPDATE_START = 'CATEGORY_UPDATE_START';
export const CATEGORY_UPDATE_SUCCESS = 'CATEGORY_UPDATE_SUCCESS';
export const CATEGORY_UPDATE_ERROR = 'CATEGORY_UPDATE_ERROR';

// Active Inactive Fare
export const UPDATE_PRICING_STATUS_START = 'UPDATE_PRICING_STATUS_START';
export const UPDATE_PRICING_STATUS_SUCCESS = 'UPDATE_PRICING_STATUS_SUCCESS';
export const UPDATE_PRICING_STATUS_ERROR = 'UPDATE_PRICING_STATUS_ERROR';

// Delete Fare
export const DELETE_PRICING_START = 'DELETE_PRICING_START';
export const DELETE_PRICING_SUCCESS = 'DELETE_PRICING_SUCCESS';
export const DELETE_PRICING_ERROR = 'DELETE_PRICING_ERROR';

// ADD UPDATE Fare
export const ADD_UPDATE_PRICING_START = 'ADD_UPDATE_PRICING_START';
export const ADD_UPDATE_PRICING_SUCCESS = 'ADD_UPDATE_PRICING_SUCCESS';
export const ADD_UPDATE_PRICING_ERROR = 'ADD_UPDATE_PRICING_ERROR';

//DELETE Content Page
export const ADMIN_DELETE_CONTENTPAGE_START = 'ADMIN_DELETE_CONTENTPAGE_START';
export const ADMIN_DELETE_CONTENTPAGE_SUCCESS = 'ADMIN_DELETE_CONTENTPAGE_SUCCESS';
export const ADMIN_DELETE_CONTENTPAGE_ERROR = 'ADMIN_DELETE_CONTENTPAGE_ERROR';

// ADMIN ROLES MODAL
export const OPEN_ADMIN_ROLES_MODAL = 'OPEN_ADMIN_ROLES_MODAL';
export const CLOSE_ADMIN_ROLES_MODAL = 'CLOSE_ADMIN_ROLES_MODAL';

// Create Admin Role
export const CREATE_ADMIN_ROLES_START = 'CREATE_ADMIN_ROLES_START';
export const CREATE_ADMIN_ROLES_SUCCESS = 'CREATE_ADMIN_ROLES_SUCCESS';
export const CREATE_ADMIN_ROLES_ERROR = 'CREATE_ADMIN_ROLES_ERROR';

// Delete Admin Role
export const DELETE_ADMIN_ROLES_START = 'DELETE_ADMIN_ROLES_START';
export const DELETE_ADMIN_ROLES_SUCCESS = 'DELETE_ADMIN_ROLES_SUCCESS';
export const DELETE_ADMIN_ROLES_ERROR = 'DELETE_ADMIN_ROLES_ERROR';

// Create Admin User
export const CREATE_ADMIN_USER_START = 'CREATE_ADMIN_USER_START';
export const CREATE_ADMIN_USER_SUCCESS = 'CREATE_ADMIN_USER_SUCCESS';
export const CREATE_ADMIN_USER_ERROR = 'CREATE_ADMIN_USER_ERROR';

// Delete Admin User
export const DELETE_ADMIN_USER_START = 'DELETE_ADMIN_USER_START';
export const DELETE_ADMIN_USER_SUCCESS = 'DELETE_ADMIN_USER_SUCCESS';
export const DELETE_ADMIN_USER_ERROR = 'DELETE_ADMIN_USER_ERROR';

// ADMIN ROLES MODAL
export const OPEN_ADMIN_USER_MODAL = 'OPEN_ADMIN_USER_MODAL';
export const CLOSE_ADMIN_USER_MODAL = 'CLOSE_ADMIN_USER_MODAL';

// Get Admin User
export const GET_ADMIN_USER_START = 'GET_ADMIN_USER_START';
export const GET_ADMIN_USER_SUCCESS = 'GET_ADMIN_USER_SUCCESS';
export const GET_ADMIN_USER_ERROR = 'GET_ADMIN_USER_ERROR';

// HEADER MODAL
export const OPEN_HEADER_MODAL = 'OPEN_HEADER_MODAL';
export const CLOSE_HEADER_MODAL = 'CLOSE_HEADER_MODAL';

// GET_MAP_DATA 
export const GET_MAP_DATA_START = 'GET_MAP_DATA_START';
export const GET_MAP_DATA_SUCCESS = 'GET_MAP_DATA_SUCCESS';
export const GET_MAP_DATA_ERROR = 'GET_MAP_DATA_ERROR';

export const EDIT_BOOKING_MODAL = 'EDIT_BOOKING_MODAL';
export const CANCEL_BOOKING_MODAL = 'CANCEL_BOOKING_MODAL';
export const CANCEL_MANUAL_BOOKING_MODAL = 'CANCEL_MANUAL_BOOKING_MODAL';

//Mobile Settings upload
export const MOBILE_SETTINGS_UPLOAD_START = 'MOBILE_SETTINGS_UPLOAD_START';
export const MOBILE_SETTINGS_UPLOAD_SUCCESS = 'MOBILE_SETTINGS_UPLOAD_SUCCESS';
export const MOBILE_SETTINGS_UPLOAD_ERROR = 'MOBILE_SETTINGS_UPLOAD_ERROR';

//Site Color code
export const SITE_COLOR_CODE = '#000000';

export const GET_CONTENT_START = 'GET_CONTENT_START';
export const GET_CONTENT_SUCCESS = 'GET_CONTENT_SUCCESS';
export const GET_CONTENT_ERROR = 'GET_CONTENT_ERROR';

export const CHOSE_TO_THEME_START = 'CHOSE_TO_THEME_START';
export const CHOSE_TO_THEME_SUCCESS = 'CHOSE_TO_THEME_SUCCESS';
export const CHOSE_TO_THEME_ERROR = 'CHOSE_TO_THEME_ERROR';

export const UPDATE_PAYMENT_STATUS_START = 'UPDATE_PAYMENT_STATUS_START';
export const UPDATE_PAYMENT_STATUS_SUCCESS = 'UPDATE_PAYMENT_STATUS_SUCCESS';
export const UPDATE_PAYMENT_STATUS_ERROR = 'UPDATE_PAYMENT_STATUS_ERROR';

export const SET_USER_TYPE = 'SET_USER_TYPE';

// Delete location
export const DELETE_EMAIL_TEMPLATE_START = 'DELETE_EMAIL_TEMPLATE_START';
export const DELETE_EMAIL_TEMPLATE_SUCCESS = 'DELETE_EMAIL_TEMPLATE_SUCCESS';
export const DELETE_EMAIL_TEMPLATE_ERROR = 'DELETE_EMAIL_TEMPLATE_ERROR';

