import React, { Component } from 'react'
import withStyles from 'isomorphic-style-loader/withStyles';
import { Table, FormControl, ButtonToolbar, Button } from 'react-bootstrap';
import s from './EmailTemplateManagement.css';
import bt from '../../../components/commonStyle.css';
import { FormattedMessage, injectIntl } from 'react-intl';
import cx from 'classnames';
import messages from '../../../locale/messages';
import CustomPagination from '../../CustomPagination';
import { flowRight as compose } from 'lodash';
import PropTypes from 'prop-types';
import Link from '../../Link/Link';
import EditIcon from '../../../../public/Icons/edit.png';
import TrashIcon from '../../../../public/Icons/bin.svg'
import { connect } from 'react-redux';
import { deleteEmailTemplate } from '../../../actions/siteadmin/deleteEmailTemplate'
import Loader from '../../../components/Common/Loader';

export class EmailTemplateManagement extends Component {
    static propTypes = {
        emailTemplateList: PropTypes.object,
    }
    constructor(props) {
        super(props)

        this.state = {
            currentPage: 1,
        }
        this.paginationData = this.paginationData.bind(this);
        // this.handleSearchClick = this.handleSearchClick.bind(this);
        // this.handleSearchChange = this.handleSearchChange.bind(this);
        // this.handleChange = this.handleChange.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
    }
    // handleSearchClick(searchList) {
    //     const { locationList: { refetch } } = this.props
    //     let variables = {
    //         currentPage: 1,
    //         searchList: searchList
    //     }
    //     this.setState({ currentPage: 1 })
    //     refetch(variables)
    // }
    // handleSearchChange(e) {
    //     let self = this
    //     if (self.state.typingTimeout) {
    //         clearTimeout(self.state.typingTimeout)
    //     }
    //     self.setState({
    //         searchList: e.target.value,
    //         typing: false,
    //         typingTimeout: setTimeout(function () {
    //             self.handleSearchClick(self.state.searchList)
    //         }, 450)
    //     })
    // }
    async handleDelete(id, currentPage) {
        const { emailTemplateList: { refetch }, deleteEmailTemplate } = this.props;
        this.setState({ currentPage: 1 });
        let variables = { currentPage: 1 };
        await deleteEmailTemplate(id, currentPage);
        refetch(variables);
    }
    // async handleChange(e, id, locationName, description, coordinates) {
    //     const { updateLocation, locationList: { refetch } } = this.props;
    //     this.setState({ currentPage: 1 });
    //     let variables = { currentPage: 1 };
    //     let isActive = e.target.value;
    //     await updateLocation(locationName, coordinates, id, description, isActive);
    //     refetch(variables);

    // }
    paginationData(currentPage) {
        const { emailTemplateList: { refetch } } = this.props;
        let variables = { currentPage };
        this.setState({ currentPage });
        refetch(variables);
    }
    render() {
        console.log(this.props, "oklokl")
        const { emailTemplateList, emailTemplateList: { getEmailTemplateList }, loading } = this.props;
        const { currentPage } = this.state;
        const { formatMessage } = this.props.intl;
        return (
            <div className={cx(s.widthInner, 'whiteDropdown', 'bgBlackTwo')}>
                <div className={cx(bt.padding2, bt.paddingTop2, s.displayInlineBlock, s.rightSide, 'floatLeftRTL', 'floatMbRightRTL')}>
                    <Link to={"/siteadmin/email-template/add"} className={cx(bt.btnPrimary, 'btnPrimaryDark')} >{formatMessage(messages.addEmailTemplate)}</Link>
                </div>
                <div class="clearfix"></div>
                {
                    loading && <div>
                        <Loader type="circle" />
                    </div>
                }
                {!loading &&
                    <div className={cx(s.tableCss, 'tableCss', 'tableSticky', 'NewResponsiveTable', 'topScrollbarTable')}>
                        <Table className={cx("table", 'topScrollbarTableContent')}>
                            <thead>
                                <tr>
                                    <th scope="col"><FormattedMessage {...messages.id} /></th>
                                    <th scope="col"><FormattedMessage {...messages.emailPurpose} /></th>
                                    <th scope="col"><FormattedMessage {...messages.action} /></th>
                                    <th scope="col"><FormattedMessage {...messages.deleteAction} /></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    emailTemplateList && emailTemplateList.getEmailTemplateList && emailTemplateList.getEmailTemplateList.EmailTemplateData && emailTemplateList.getEmailTemplateList.EmailTemplateData.length > 0 && emailTemplateList.getEmailTemplateList.EmailTemplateData.map((item, index) => {
                                        item.isActive = item.isActive == true ? 1 : 0;
                                        return (
                                            <tr key={index}>
                                                <td data-label={formatMessage(messages.id)}>{item.id}</td>
                                                <td data-label={formatMessage(messages.emailPurpose)}>{item.emailPurpose}</td>
                                                
                                                <td data-label={formatMessage(messages.action)}><Link to={'/siteadmin/email-template/edit/' + item.id} className={cx('editAlign', s.displayFlex)}><span className={'svgImg'}><img src={EditIcon} className={cx(s.editIcon, 'editIconRTL')} /></span>
                                                    <span className={s.vtrMiddle}>
                                                        <FormattedMessage {...messages.editAction} />
                                                    </span></Link></td>
                                                <td data-label={formatMessage(messages.deleteAction)} >

                                                    <Button onClick={() => this.handleDelete(item.id, currentPage)} className={cx(s.iconBtn, 'svgImg', 'btnPrimaryDeleteDark')}>
                                                        <img src={TrashIcon} className={cx(s.editIcon, 'editIconRTL')} />
                                                        <span className={s.vtrMiddle}>
                                                            <FormattedMessage {...messages.deleteAction} />
                                                        </span>
                                                    </Button>

                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                                {
                                    emailTemplateList && emailTemplateList.getEmailTemplateList && emailTemplateList.getEmailTemplateList.EmailTemplateData && emailTemplateList.getEmailTemplateList.EmailTemplateData.length == 0 && (
                                        <tr>
                                            <td colspan="12" className={s.noRecords}><FormattedMessage {...messages.noResult} /></td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </Table>
                    </div>
                }
                {
                    emailTemplateList && emailTemplateList.getEmailTemplateList && emailTemplateList.getEmailTemplateList.EmailTemplateData && emailTemplateList.getEmailTemplateList.EmailTemplateData.length > 0
                    && <div className={cx(bt.space5, bt.spaceTop5)}>
                        <CustomPagination
                            total={emailTemplateList.getEmailTemplateList.count}
                            currentPage={currentPage}
                            defaultCurrent={1}
                            defaultPageSize={10}
                            change={this.paginationData}
                            paginationLabel={emailTemplateList.getEmailTemplateList.count == 1 ? formatMessage(messages.emailTemplate) : formatMessage(messages.emailTemplate)}
                        />
                    </div>
                }
            </div>
        )
    }
}
const mapState = (state) => ({
    loading: state.intl.loading
});
const mapDispatch = {
    deleteEmailTemplate,
};
export default injectIntl(compose(
    withStyles(s, bt),
    connect(mapState, mapDispatch)
)(EmailTemplateManagement));

