import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import EmailTemplateManagement from '../../../components/SiteAdmin/EmailTemplateManagement/EmailTemplateManagement'
import { flowRight as compose } from 'lodash';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './EmailTemplatePage.css';
import { injectIntl } from 'react-intl';
import messages from '../../../locale/messages';
import getEmailTemplateList from './getEmailTemplateList.graphql';
import { graphql } from 'react-apollo';

export class EmailTemplatePage extends Component {

  render() {
    const { formatMessage } = this.props.intl;
    const { emailTemplateList: getEmailTemplateList } = this.props;

    return (
      <div className={s.root}>
        <div className={s.container}>
          <div className={s.heading}>
            {formatMessage(messages.emailTemplatePageManagement)}
          </div>
          <div className={s.paddingRoutesSection}>
            <EmailTemplateManagement emailTemplateList={getEmailTemplateList} />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {

}

export default compose(
  injectIntl,
  withStyles(s),
  graphql(getEmailTemplateList, {
    name: 'emailTemplateList',
    options: {
        variables: {
            currentPage: 1,
        },
        fetchPolicy: 'network-only'
    }
  }),
  connect(mapStateToProps, mapDispatchToProps)
)(EmailTemplatePage)
